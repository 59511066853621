import _slugify from 'slugify'
_slugify.extend({
  ö: 'oe',
  Ö: 'Oe',
  ä: 'ae',
  Ä: 'Ae',
  ü: 'ue',
  Ü: 'Ue',
  ß: 'ss',
  ẞ: 'SS',
})

export function slugify(s, replacement = '-') {
  return encodeURIComponent(
    _slugify(s, {
      lower: true,
      replacement,
      remove: /[^A-Za-z0-9- ]/g,
    })
  )
}

/**
 * @description Reduce channels data structure to one channel filtered by
 * portalId.
 * @param {Array} channels - List of channel objects
 * @returns {Object, null}
 */
export function getPortalChannel(channels = [], $config) {
  return channels.reduce((result, c) => {
    const portalId = parseInt(c.relationships.portal.id)
    if (portalId === $config.portalId) {
      return c
    }
    return result
  }, null)
}

/**
 * Method will parse channels belonging to portal.
 * Portal check is done in $config.portalId field that is as Number value
 *
 * @param {*} channels
 * @param {*} $config
 * @returns array of channel slugs ['name', 'othername']
 */
export function parseChannelSlugs(channels = [], $config) {
  // Reduce Channels by portalId
  const channel = getPortalChannel(channels, $config)
  if (channel === null) return []
  const parentSlugs = channel.relationships.parents.map((c) => c.attributes.slug).filter((slug) => !!slug)
  return parentSlugs.concat(channel.attributes.slug)
}

/**
 * Method will parse channels belonging to portal.
 * Portal check is done in $config.portalId field that is as Number value
 * @returns array of channel slugs ['Name', 'Othername']
 *
 * @param {*} channels
 * @param {*} $config
 * @returns array of channel names
 */
export function parseChannelNames(channels = [], $config) {
  // Reduce Channels by portalId
  const channel = getPortalChannel(channels, $config)
  // add check if portal channel is defined, map it to name and filer values
  if (channel === null) return []
  const parentSlugs = channel.relationships.parents.map((c) => c.attributes.name).filter((name) => !!name)
  return parentSlugs.concat(channel.attributes.name)
}

export default ({ app }, inject) => {
  inject('getPortalChannel', getPortalChannel)
  inject('parseChannelSlugs', parseChannelSlugs)
  inject('slugify', slugify)
}
