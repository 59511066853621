import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44c5a156 = () => interopDefault(import('../pages/abo/index.vue' /* webpackChunkName: "pages/abo/index" */))
const _023d37e0 = () => interopDefault(import('../pages/agb.vue' /* webpackChunkName: "pages/agb" */))
const _ed6e5ce8 = () => interopDefault(import('../pages/benutzerkonto.vue' /* webpackChunkName: "pages/benutzerkonto" */))
const _030e1076 = () => interopDefault(import('../pages/booklet.vue' /* webpackChunkName: "pages/booklet" */))
const _6cfab5c2 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _ee2c3ef2 = () => interopDefault(import('../pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _9065bcda = () => interopDefault(import('../pages/epaper.vue' /* webpackChunkName: "pages/epaper" */))
const _14f8f0db = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _0557db05 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _6a426548 = () => interopDefault(import('../pages/offenlegung.vue' /* webpackChunkName: "pages/offenlegung" */))
const _1e06e84e = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _768cea26 = () => interopDefault(import('../pages/subscribed.vue' /* webpackChunkName: "pages/subscribed" */))
const _94cb9fb8 = () => interopDefault(import('../pages/subscription-error.vue' /* webpackChunkName: "pages/subscription-error" */))
const _19bec9e2 = () => interopDefault(import('../pages/suche.vue' /* webpackChunkName: "pages/suche" */))
const _112bf7e0 = () => interopDefault(import('../pages/ueber-uns.vue' /* webpackChunkName: "pages/ueber-uns" */))
const _a46d8020 = () => interopDefault(import('../pages/widerruf.vue' /* webpackChunkName: "pages/widerruf" */))
const _ae84ac94 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4832e3e6 = () => interopDefault(import('../pages/abo/_slug.vue' /* webpackChunkName: "pages/abo/_slug" */))
const _1779d151 = () => interopDefault(import('../pages/archive/_id.vue' /* webpackChunkName: "pages/archive/_id" */))
const _24b76cf6 = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _0c2c8302 = () => interopDefault(import('../pages/author/_id.vue' /* webpackChunkName: "pages/author/_id" */))
const _20c3d33c = () => interopDefault(import('../pages/channel/_slug.vue' /* webpackChunkName: "pages/channel/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/abo",
    component: _44c5a156,
    name: "abo"
  }, {
    path: "/agb",
    component: _023d37e0,
    name: "agb"
  }, {
    path: "/benutzerkonto",
    component: _ed6e5ce8,
    name: "benutzerkonto"
  }, {
    path: "/booklet",
    component: _030e1076,
    name: "booklet"
  }, {
    path: "/cookie-policy",
    component: _6cfab5c2,
    name: "cookie-policy"
  }, {
    path: "/datenschutz",
    component: _ee2c3ef2,
    name: "datenschutz"
  }, {
    path: "/epaper",
    component: _9065bcda,
    name: "epaper"
  }, {
    path: "/impressum",
    component: _14f8f0db,
    name: "impressum"
  }, {
    path: "/newsletter",
    component: _0557db05,
    name: "newsletter"
  }, {
    path: "/offenlegung",
    component: _6a426548,
    name: "offenlegung"
  }, {
    path: "/registered",
    component: _1e06e84e,
    name: "registered"
  }, {
    path: "/subscribed",
    component: _768cea26,
    name: "subscribed"
  }, {
    path: "/subscription-error",
    component: _94cb9fb8,
    name: "subscription-error"
  }, {
    path: "/suche",
    component: _19bec9e2,
    name: "suche"
  }, {
    path: "/ueber-uns",
    component: _112bf7e0,
    name: "ueber-uns"
  }, {
    path: "/widerruf",
    component: _a46d8020,
    name: "widerruf"
  }, {
    path: "/",
    component: _ae84ac94,
    name: "index"
  }, {
    path: "/abo/:slug",
    component: _4832e3e6,
    name: "abo-slug"
  }, {
    path: "/archive/:id?",
    component: _1779d151,
    name: "archive-id"
  }, {
    path: "/article/:id?",
    component: _24b76cf6,
    name: "article-id"
  }, {
    path: "/author/:id?",
    component: _0c2c8302,
    name: "author-id"
  }, {
    path: "/channel/:slug?",
    component: _20c3d33c,
    name: "channel-slug"
  }, {
    path: "/autor/:authorName([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _0c2c8302,
    name: "author-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)+/:slug([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _24b76cf6,
    name: "article-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)*/:slug([a-z0-9A-F%-]+)",
    component: _20c3d33c,
    name: "channel-canonical"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
