<template>
  <footer class="noprint" role="footer">
    <div class="footer__top">
      <NuxtLink :to="{ name: 'index' }">
        <FurcheLogo />
      </NuxtLink>
      <div class="footer__top-text">
        <DossierItems />
        <ul role="list" class="footer-navigation-top">
          <li v-for="(navItem, index) in footerNavigationTop" :key="index" role="listitem">
            <ArticleLink
              v-if="navItem.attributes.link.type === 'ContentLink' && navItem.attributes.link.relationships.article"
              :article="navItem.attributes.link.relationships.article"
              class="footer__bottom__link"
            >
              {{ navItem.attributes.link.attributes.text }}
            </ArticleLink>
            <NavigationLink
              v-else-if="navItem.attributes.link.type === 'ChannelLink'"
              :channel="navItem.attributes.link.relationships.channel"
              class="footer__bottom__link"
            >
              {{ navItem.attributes.link.attributes.text }}
            </NavigationLink>
            <a
              v-else-if="navItem.attributes.link.attributes.url"
              :href="navItem.attributes.link.attributes.url"
              :target="navItem.attributes.link.attributes.target"
              class="footer__bottom__link"
            >
              {{ navItem.attributes.link.attributes.text }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer__bottom">
      <span class="footer__copyright">© {{ new Date().getFullYear() }} DIE FURCHE</span>
      <ul role="list" class="footer-navigation-bottom">
        <li v-for="(navItem, index) in footerNavigationBottom" :key="index" role="listitem">
          <ArticleLink
            v-if="navItem.attributes.link.type === 'ContentLink' && navItem.attributes.link.relationships.article"
            :article="navItem.attributes.link.relationships.article"
            class="footer__bottom__link"
          >
            {{ navItem.attributes.link.attributes.text }}
          </ArticleLink>
          <NavigationLink
            v-else-if="navItem.attributes.link.type === 'ChannelLink'"
            :channel="navItem.attributes.link.relationships.channel"
            class="footer__bottom__link"
          >
            {{ navItem.attributes.link.attributes.text }}
          </NavigationLink>
          <a
            v-else-if="navItem.attributes.link.attributes.url"
            :href="navItem.attributes.link.attributes.url"
            :target="navItem.attributes.link.attributes.target"
            class="footer__bottom__link"
          >
            {{ navItem.attributes.link.attributes.text }}
          </a>
        </li>
        <li role="listitem">
          <a href="javascript:void(0);" @click="$store.dispatch('consent/showConsentManager', $config)">
            Cookie Einstellung
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import FurcheLogo from '@/components/ui/icons/FurcheLogo1945.svg'
import DossierItems from '@/components/dossier/dossier-items'
import NavigationLink from '@/components/ui/page/navigation-link'
import ArticleLink from '@/components/article/link'

export default {
  components: {
    FurcheLogo,
    DossierItems,
    NavigationLink,
    ArticleLink,
  },
  computed: {
    footerNavigationBottom() {
      return this.$store.getters['navigation/footerNavigationBottom']
    },
    footerNavigationTop() {
      return this.$store.getters['navigation/footerNavigationTop']
    },
  },
}
</script>

<style lang="scss">
@use '../../css/import/vars.scss';

.footer {
  max-width: vars.$channel-max-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-top: 30px;
  margin: 40px 20px 20px;
  width: calc(100% - 40px);
  border-top: 1px solid vars.$color-light-grey;

  @media screen and (min-width: vars.$screen-width-767) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .logoSVG {
    transform: scale(0.6);
    @media screen and (min-width: vars.$screen-width-767) {
      transform: scale(1);
    }
  }
  .logoText {
    display: none;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;

    @media screen and (min-width: vars.$screen-width-767) {
      text-align: left;
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__top-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: vars.$screen-width-767) {
      flex-direction: row;
    }

    .dossier-top-list {
      display: flex;
      flex-wrap: wrap;

      &__item {
        padding-bottom: 20px;

        @media screen and (min-width: vars.$screen-width-767) {
          padding-bottom: 0;
        }
      }
    }
  }

  &-navigation-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: vars.$screen-width-767) {
      flex-direction: row;
    }
    a {
      color: vars.$color-text-light-gray;
      font-size: 16px;
      font-weight: 600;
      font-variation-settings: 'wght' 600;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @media screen and (min-width: vars.$screen-width-767) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__copyright {
    font-size: 13px;
    color: vars.$color-line-gray;
    order: 1;
    @media screen and (min-width: vars.$screen-width-767) {
      order: 0;
    }
  }

  &-navigation-bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    a {
      color: vars.$color-line-gray;
      font-size: 13px;
    }
  }

  ul[role='list'] {
    margin: 0;
    padding: 0 1rem 10px 1rem;
    list-style-type: none;

    li[role='listitem'] {
      padding: 0;
      line-height: 1;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      a {
        padding: 4px 8px 20px 8px;
        @media screen and (min-width: vars.$screen-width-767) {
          padding: 4px 8px 4px 8px;
        }
      }

      &:hover a {
        color: vars.$color-turquoise;
        text-decoration: underline;
        transition-duration: 0.3s;
      }

      &:before {
        display: none;
      }
    }
  }
}
</style>
